.hb1 {
  color: #056292;
  margin-top: 30px;
}
.hp1 {
  color: #96153a;
  text-align: center;
  margin-top: 30px;
}
.h3b1 {
  color: #056292;
}

.h3p1 {
  color: #96153a;
}
.hw1 {
  color: #fff;
}
.pw1 {
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AvenirBlack";
}

ul {
  font-family: "AvenirRoman";
}
.en1 {
  font-family: "AvenirBlack";
}
.en2 {
  font-family: "AvenirRoman";
  text-align: justify;
}
.ar1 {
  font-family: "DinarMedium";
}

.ar2 {
  font-family: "DinarLight";
  text-align: justify;
  direction: rtl;
}
.arb2 {
  font-family: "Almarai";
  text-align: justify;
  direction: rtl;
}

.bold {
  font-weight: bolder;
}

.enl {
  text-align: justify;
}

.wp {
  color: #fff;
}

.center {
  text-align: center;
}
