.promos {
  background-color: #fff;
  border: 1px solid #96153a;
  padding: 60px;
  margin: 25px;
}

.tethqar {
  background-color: #fff;
  border: 1px solid #96153a;
  padding: 60px;
  margin: 25px;
}
