@import "~react-image-gallery/styles/css/image-gallery.css";

.sqrimg {
  width: 100%;
  height: 300px;
  background-color: #000;
  object-fit:contain;
  margin-bottom: 25px;
}
.hideSection {
  background-color: rgba(0, 0, 0, 0.2);
}
.custom-dot-list-style{
  position: relative;
  top: 350px;
  
}
.btn-view-more{
  display: inline-block;
  position: relative;
  top: -20px;
  background: #056292;
  color:#fff ;
  width: 98%;
  margin: 0;
  text-align: center;
  text-decoration: none;
}
.btn-view-more:hover{
  color: white;
  text-shadow: 2px 2px 5px #00000083;
}