.navstyle{

    align-items: center;
    font-weight: bold;
    text-align: center;
    width: 100%;
    padding: 30px 10px;
    font-family: "EurostileBold";


}

.navbar{
    display: flex;
}

.nav-column{
    flex:1;
    padding: 25px;
    text-align: center;
}

.layer-1-image {

    height: 85px; /* Set the height to 200px */
    z-index: 100px;
    position: absolute;
    margin-left: 250px;
  }

.red{
    background-color: #c24f49;
}

.green{
    background-color: #76a271;
}

.blue{
    background-color: #55828f;
}

.yellow{
    background-color: #dbbc55;
}

.tethqarbg{
    background-color: #e4dcce;
}