.head2 {
    color: #000000;
    font-size:xx-large;
    text-align: left;
  }
  .para1 {
    color: #ffffff;
    font-size: 14px;
    text-align: left;
  }
  .bn {
    background-color: grey;
    border: 2px;
    border-color: #ffffff;
    border-radius: 25px;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .img2 {
    height: 250px;
    width: auto;
  }
.detailstext2 {
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-family: "EurostileBold";
}
.detailstext2arb {
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-family: "EurostileBold";
  text-align: right;
}

  .faqbackground {
    padding-right: 80px;
    padding-left: 80px;
  }
  .bodybg {
    background-color: #785537;
  }

.headdetails4 {

  color: #000000;
  border-radius: 7px;
  font-family: "BUDHAND2";
  padding: 15px 20px;
  text-align: right;
  align-items: right;
}

.abcd {
    margin-top: 120px;
}
.faqhead{
  height: 80px;
  width: auto;
}
.faqimage{

  width: 100%;
}
.rtl{
  direction: rtl;
}