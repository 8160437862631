.card-b
{
    background-color: #fff;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    height: 350px;
}

.b-img
{
    height: 200px;
}

.content
{
    max-height: 40px;
    overflow: hidden;
}