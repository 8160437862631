
.head1 {
  color: #96153a;
}
.head2 {
  color: #000;
}
.bn {
  width: 100%;
}
