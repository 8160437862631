.head4 {
    color: #000000;
    font: size 200px;
    text-align: left;
  }
  .para4 {
    color: #ffffff;
    font-size: 17px;
    text-align: left;
    font-family: "EurostileBold";
  }
  .para4arb {
    color: #ffffff;
    font-size: 16px;
    text-align: right;
    font-family: "EurostileBold";
  }
  .rightalign{
    align-items: right;
    text-align: right;
  }
  .bn {
    background-color: grey;
    border: 2px;
    border-color: #ffffff;
    border-radius: 25px;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .img1 {
    height: 500px;
    width: 500px;
  }
.stickerimg{
  height: 130px;
  width: 750px;
  padding-top: 0%;
  position: absolute;
  left: 450px;

}
  .Headimg{
    height: 150px;
    width: 450px;
  }
  .aboutbackground {
    background-image: url("../../assets/About Us BG.png");
    padding: 25px;
    background-size: contain;
  }

  .text-button{
    display: inline-block;
    padding: 1px 16px;
    color: black;
    border-radius: 50px;
    transform: rotate(-3deg);
    cursor: pointer;
  }
.white{
  background-color: #ffffff;
}