body {
  overflow-x: hidden;
}
.ln {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

.ln button {
  background-color: #fff;

  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  color: #056292;
  font-weight: bold;
}
