.grid-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding: 10px 40px;
}

.grid-item {
  position: relative; /* Ensure positioning context for absolute positioning */
  overflow: hidden; /* Hide overflow content */
  text-align: center;
  align-items: flex-end;
}

.grid-item img {
  width: 100%; /* Ensure the image fills its grid item */
  height:auto; /* Ensure the image fills its grid item */
  object-fit: cover; /* Maintain aspect ratio while covering the grid item */
}
.servicehead{
  width: 100%;
}

.servicebackground {
  background-image: url("../../assets/Services\ BG.png");
  padding: 25px;
  background-size:cover;
  background-repeat:no-repeat;
}


