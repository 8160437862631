.icons {
  width: 40vw;
}
.grid1 {
  background-color: #bde6f0;
  padding: 10px;
  height: 350px;
  padding: 20px;
}
.grid2 {
  background-color: #ebc5c7;
  padding: 10px;
  height: 350px;
  padding: 20px;
}
.gHead1 {
  color: #056292;
}
.gHead2 {
  color: #96153a;
}

/*---------------------------------------*/
.border-box {
  padding: 10px;
  border: 4px solid #056292;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 500px;
  max-width: 90vw;
}
.ul {
  width: 70%;
  margin: auto;
}

.lb-box {
  background-color: #bde6f0;
  padding: 10px;
}

.lp-box {
  background-color: #ebc5c7;
  padding: 10px;
}

.bluebox {
  text-align: center;
  background-color: #056292;
  padding: 10px;
  color: #fff;
  width: 750px;
  max-width: 90vw;
  margin-top: 10px;
  margin-bottom: 30px;
}
.purplebox {
  text-align: center;
  background-color: #96153a;
  padding: 10px;
  color: #fff;
  width: 700px;
  max-width: 90vw;
  margin-top: 10px;
  margin-bottom: 30px;
}
