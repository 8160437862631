.txt1 {

  text-align: center;
  color: #056292;
}
.txt0
{
    color:#056292;
    font-weight: bolder;
}
.values img{
  border-radius: 100%;
}