.bg {
    background-color: #000000;

    background-image: url("../../assets/Process BG .png");
    background-size: cover;
  }

.text2 {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-family: "EurostileBold";
  }

.headtext2 {
  font-size: 50px;
  color: rgb(255, 255, 255);
  font-family: "VCR_OSD_MONO_1";
}
.text2arb {
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-family: "EurostileBold";
  text-align: right;
}

.headtext2arb {
font-size: 50px;
color: rgb(255, 255, 255);
font-family: "VCR_OSD_MONO_1";
text-align: right;
}
  .heading {

    color: rgb(255, 255, 255);
    text-align: center;
    font-family: "VCR_OSD_MONO_1";
  }