.banner {
  background-color: rgba(255, 0, 98, 0.144);
  padding: 25px;
}
.head1 {
  color: #96153a;
}
.head2 {
  color: #000;
}
.bn {
  width: 100%;
}
