
  .contactbutton {
    position: absolute;
    background-color: #55828f;
    border: 2px;
    border-color: #ffffff;
    border-radius: 25px;
    color: white;
    padding: 10px ;
    text-align: center;
    align-items: center;
    font-weight: bold;
    font-family: "EurostileBold";
    display: inline-block;
    font-size: 20px;
    margin: 20px 2px;
    cursor: pointer;
    width: 250px;
    height: 50px;
  }


  .contactbuttonmob {
    position: absolute;
    background-color: #55828f;
    border: 2px;
    border-color: #ffffff;
    border-radius: 25px;
    color: white;
    padding: 1px 10px ;
    text-align: center;
    align-items: center;
    font-weight: bold;
    font-family: "EurostileBold";
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
    width: 100px;

  }

.contactusbackground{
  background-image: url("../../assets/Contact Us BG.png");
  padding: 25px;
  background-size: contain;
}
.contactusframe{
  width: 100%;
}
.aligncenter{
  display: flex;
  justify-content: center;
}