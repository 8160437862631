
.footerfont{
    font-weight: bold;
    font-size: xx-large;
    text-align: center;
}

.footertethqar{
    font-weight: bold;
    font-size:larger;
    text-align: center;
}
