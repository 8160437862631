.activeBn {
  color: #96153a;
  font-weight: bolder;
  font-size: larger;
  background: none;
}
.activeBn2 {
  color: #96153a;
  font-weight: bolder;
  font-size: larger;
  background: none;
}
.section {
  background-color: #96153a2d;
  padding: 20;
}
