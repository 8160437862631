.homebackground {
  background-image: url("../../assets/Home Page BG.png");
  background-size: cover;

}

.counter h1 {
  font-size: 48px;
  color: #333;
  text-align: center;
  animation: countAnimation 1s ease-out;
}

@keyframes countAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


  .head1 {
    padding-top: 10px;
    color: #ffffff;
    font-size: 60px;
    text-align: center;
    font-family: "BUDHAND2";
  }
  .headp2 {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    font-family: "EurostileBold";
  }
  .head1mob {
    padding-top: 10px;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    font-family: "BUDHAND2";
  }
  .headp2mob {
    color: #ffffff;
    font-size: 9px;
    text-align: center;
    font-family: "EurostileBold";
  }
  .parabanner {
    color: #000000;
    font-size: 14px;
    padding-top: 10px;
    padding-left: 5px;
    font-family: "EurostileBold";
  }
  .parabanner1 {
    color: #000000;
    font-size: 26px;
    padding-top: 14px;
    font-family: "BUDHAND2";
  }
  .parabannerarb {
    color: #000000;
    font-size: 14px;
    padding-top: 10px;
    font-family: "EurostileBold";
    text-align: right;
  }
  .parabanner1arb {
    color: #000000;
    font-size: 28px;
    padding-top: 10px;
    font-family: "BUDHAND2";
    text-align: right;
  }
  .bn1 {
    background-color: #55828f;
    border: 2px;
    border-color: #ffffff;
    border-radius: 25px;
    color: white;
    padding: 5px 25px;
    text-align: center;
    font-weight: bold;
    font-family: "EurostileBold";
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  .img {
    height: 250px;
    width: 250px;
  }
  .homeimg{
    position: relative;
    height: 350px;
    width: 600px;
    right: 200px;
  }
.adverticapadding{
  padding-top: 200px;
  position: relative;
}

.navbar1{
  display: flex;
  flex:1;

}
.navstyle1{
  cursor: pointer;
  flex: 4;
  align-items: center;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: auto;
}
.alignright11{
  align-items:end;
  text-align: right;
}
.rtl{
  direction: rtl;
}