a {
  margin: 5px;
}
.footbar {
  background-color: #136c98;
  color: #fff;
  text-align: center;
}
.book {
  font-family: "AvenirBook";
}
