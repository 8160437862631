html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"),
    url(./fonts/AvenirLTStd-Black.otf) format("truetype");
}
@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirRoman"),
    url(./fonts/AvenirLTStd-Roman.otf) format("truetype");
}
@font-face {
  font-family: "AvenirBook";
  src: local("AvenirBook"), url(./fonts/AvenirLTStd-Book.otf) format("truetype");
}

@font-face {
  font-family: "DinarMedium";
  src: local("DinarMedium"),
    url(./fonts/GEDinarOneMedium_0.otf) format("truetype");
}

@font-face {
  font-family: "DinarLight";
  src: local("DinarLight"),
    url(./fonts/GEDinarOneLight_0.otf) format("truetype");
}

@font-face {
  font-family: "Almarai";
  src: local("Almarai"), url(./fonts/Almarai-Regular.ttf) format("truetype");
}
@font-face {
  font-family: 'BUDHAND2';
  src: local('BUDHAND2'), url(./fonts/BUDHAND2.TTF) format('truetype');
}

@font-face {
  font-family: 'VCR_OSD_MONO_1';
  src: local('VCR_OSD_MONO_1'), url(./fonts/VCR_OSD_MONO_1.001.ttf) format('truetype');
}

@font-face {
  font-family: 'EurostileBold';
  src: local('EurostileBold'), url(./fonts/EurostileBold.ttf) format('truetype');
}