

.harddrive {
  height: 500px;
  width: 400px;
  position: absolute;
  left: 0px;
}
.reviewimage{
  height: 600px;
  width: 780px;
  position:relative;
  left: 400px;

}
.reviewimagearb{
  height: 600px;
  width: 780px;
  position:relative;


}

  .reviewbackground {
    background-image: url("../../assets/Reviews BG.png");
    background-size: cover;
    padding: 0%;
    margin: 0%;
  }
.reviewhead{
  font-size: 50px;
  font-family: "EurostileBold";
  color: white;
  font-weight: bold;
  z-index: 10;
}

.reviewheadmob{
  font-size: 35px;
  font-family: "EurostileBold";
  color: white;
  font-weight: bold;
  z-index: 10;
}

.reviewtext{
  font-size: 13px;
  position: relative;
  text-align: end;
  font-weight: bold;
  font-family: "EurostileBold";

}