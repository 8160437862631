.overlay {
  position: absolute;
  top: 126.5px;
}
.overlay2 {
  position: absolute;
  top: 116px;
  transform: rotateY(180deg);
  overflow-x: hidden;
}
.top {
  z-index: 2;
}
.carousel {
  height: 50vw;
}

.carousel-caption {
  position: absolute;
  top: 15vw;
}
